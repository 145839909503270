import QueryString from 'qs'
import axios from '../../../axios'
import { customLog, showToast } from '../../../utility/Utils'

import { store } from '../../storeConfig/store'

export const TOGGLE_LOADER = 'TOGGLE_LOADER'
export const SET_INSPECTOR_DATA = 'SET_INSPECTOR_DATA'
export const UNSET_INSPECTOR_DATA = 'UNSET_INSPECTOR_DATA'
export const SET_AST_INSPECTOR_DATA = 'SET_AST_INSPECTOR_DATA'
export const GET_INSPECTOR_DATATABLE_DATA = 'GET_INSPECTOR_DATATABLE_DATA'
export const GET_INSPECTOR_ZIPCODE_DATATABLE_DATA = 'GET_INSPECTOR_ZIPCODE_DATATABLE_DATA'
export const GET_INSPECTOR_DAY_SCHEDULE_DATATABLE_DATA = 'GET_INSPECTOR_DAY_SCHEDULE_DATATABLE_DATA'

export const toggleLoading = (visibility) => {
  return {
    type: TOGGLE_LOADER,
    payload: visibility
  }
}

export const fetchInspectors = () => {
  return async (dispatch, getState) => {
    try {
      const userData = getState().auth.userData
      dispatch(toggleLoading(true))
      const getAllInspectorsDataRes = await axios({
        url: 'WebAPI/ManagerController/getAllInspectors',
        method: 'POST',
        data: QueryString.stringify({
          user_master_id: userData?.user_master_id
        })
      })
      dispatch(toggleLoading(false))
      const {
        data,
        ast_inspectors,
        ResponseCode,
        ResponseMsg,
        Result,
        ServerTime,
        RequestId
      } = getAllInspectorsDataRes.data
      if (
        ResponseCode &&
        Result.toLowerCase() === 'true' &&
        data &&
        Array.isArray(data) &&
        data.length
      ) {
        const parsedinspector = data.map((inspector) => {
          const { user_master_id, first_name, last_name } = inspector

          return {
            ...inspector,
            value: user_master_id,
            label: `${first_name} ${last_name}`
          }
        })

        dispatch({
          type: SET_INSPECTOR_DATA,
          payload: parsedinspector
        })

        const parsedAstInspectors = ast_inspectors.map((inspector) => {
          const { user_master_id, first_name, last_name } = inspector

          return {
            ...inspector,
            is_ast_inspector: true,
            value: user_master_id,
            label: `${first_name} ${last_name}`
          }
        })

        dispatch({
          type: SET_AST_INSPECTOR_DATA,
          payload: parsedAstInspectors
        })
      } else {
        // showToast("", , true)
        throw new Error(ResponseMsg)
      }
    } catch (err) {
      dispatch(toggleLoading(false))
      customLog(err.response?.data?.ResponseMsg ?? err.message)
      showToast('', err.response?.data?.ResponseMsg ?? err.message, true)
    }
  }
}

export const getManagerInspectors = (id, params = {}) => {
  return async (dispatch) => {
    return axios.get(`/manager/${id}/inspector`, { params }).then(({ data }) => {
      const inspectors = data.payload.data.map(ins => {
        return {
          ...ins,
          value: ins.user_master_id,
          label: `${ins.first_name} ${ins.last_name}`
        }
      })

      dispatch({
        type: SET_INSPECTOR_DATA,
        payload: inspectors
      })

      
      return data
    })
  }
}

export const getManagerAsstInspectors = () => {
  return async (dispatch) => {
    return axios.get(`/manager/astinspector/list`).then(({ data }) => {
      const inspectors = data.payload.data
        .filter(inspector => inspector.is_select)
        .map(inspector => ({ ...inspector, is_ast_inspector: true }))

      dispatch({
        type: SET_AST_INSPECTOR_DATA,
        payload: inspectors
      })

      return data
    })
  }
}

export const clearInspectors = () => {
  return {
    type: UNSET_INSPECTOR_DATA
  }
}

export const getInspectorDataTable = (params) => {
  return async (dispatch) => {
    await axios
      .get('/inspector/datatable', {
        params
      })
      .then(({ data }) => {
        dispatch({
          type: GET_INSPECTOR_DATATABLE_DATA,
          data: data.payload.data,
          totalPages: data.payload.meta.last_page,
          params
        })
      })
  }
}

export const getInspectorZipCodeDataTable = (id, params) => {
  return async (dispatch) => {
    await axios
      .get(`/inspector/${id}/zipcode/datatable`, {
        params
      })
      .then(({ data }) => {
        dispatch({
          type: GET_INSPECTOR_ZIPCODE_DATATABLE_DATA,
          data: data.payload.data,
          totalPages: data.payload.meta.last_page,
          params
        })
      })
  }
}

export const inspectorZipCodeAssign = (id, payload) => {
  return async () => {
    await axios.post(`/inspector/${id}/zipcode`, payload).then(({ data }) => {
      const { message } = data
      // showToast('', message, false)
      return data
    })
  }
}

export const inspectorZipCodeAssignedRemove = (id) => {
  return async () => {
    await axios.delete(`/inspector/zipcode/${id}`).then(({ data }) => {
      const { message } = data
      // showToast('', message, false)
      return data
    })
  }
}

export const getInspectorDayScheduleDataTable = (id, params) => {
  return async (dispatch) => {
    await axios
      .get(`/inspector/${id}/dayschedule/datatable`, {
        params
      })
      .then(({ data }) => {
        dispatch({
          type: GET_INSPECTOR_DAY_SCHEDULE_DATATABLE_DATA,
          payload: {
            data: data?.payload?.data,
            totalPages: data?.payload?.meta?.last_page || 1,
            params
          }
        })
      })
  }
}

export const deleteInspector = (id) => {
  return async () => {
    await axios.delete(`/inspector/${id}`).then(({ data }) => {
      const { message } = data
      showToast('', message, false)
      return data
    })
  }
}

export const createInspector = (payload) => {
  return async () => {
    return await axios.post(`/inspector`, payload).then(({ data }) => {
      const { message } = data
      showToast('', message, false)
      return data
    })
  }
}

export const getInspector = (id) => {
  return async () => {
    return axios.get(`/inspector/${id}`).then(({ data }) => {
      // ** store in redux [only if required]
      return data
    })
  }
}

export const updateInspector = (id, payload) => {
  return async () => {
    return await axios.put(`/inspector/${id}`, payload).then(({ data }) => {
      const { message } = data
      showToast('', message, false)
      return data
    })
  }
}